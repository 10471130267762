<template>
    <section v-if="props.part && props.partChartData">
        <h2 class="part-page-heading">{{ $t("PartDetail.StockAndPriceHistory") }}</h2>
        <div class="w-full h-[640px] md:h-[600px] lg:h-[450px]">
            <PartChart
                :part="props.part"
                part-key="part-detail"
                :initial-chart-data="props.partChartData"
                preview-height="200"
                separate-charts />
        </div>
    </section>
</template>
<script setup lang="ts">
const props = defineProps<{ part?: CommonPart; partChartData?: PartChartData | null }>();
</script>
