<template>
    <div
        v-if="props.partDetail"
        class="bg-white lg:top-20 lg:mb-7 lg:z-10 lg:sticky lg:shadow-md group"
        :class="{ 'is-sticky': isStickyHeader }">
        <div class="p-2 grid gap-4 items-start sm:grid-cols-[auto_1fr] lg:grid-cols-[auto_1fr_34rem]">
            <PartHeaderCarousel
                v-if="props.partDetail && props.partDetail.Images"
                :images="props.partDetail.Images"
                class="sm:w-52" />
            <div class="flex flex-col gap-2">
                <div class="flex flex-wrap gap-4 items-end">
                    <h1 class="my-0 text-gray">
                        <div class="text-base">{{ props.partDetail.ManufacturerName }}</div>
                        <span>{{ props.partDetail.DisplayPartNumber }}</span>
                    </h1>
                    <div
                        v-if="props.partDetail.InStock"
                        class="rounded-sm font-bold text-lg px-3 py-1.5 text-success-900 bg-success-200 whitespace-nowrap">
                        <span class="fass fa-check green"></span>
                        {{ $t("Global.InStock") }}
                    </div>
                </div>
                <div
                    v-if="props.partDetail.CategoryHierarchy"
                    class="flex flex-wrap gap-x-2 items-center max-md:leading-8 max-md:gap-y-2">
                    <template v-for="(category, index) in props.partDetail.CategoryHierarchy" :key="category.Path">
                        <i v-if="index > 0" class="fass fa-chevron-right"></i>
                        <NuxtLink :href="localePath('/part-category/' + category.Path)">
                            {{ category.Name }}
                        </NuxtLink>
                    </template>
                </div>

                <div class="lg:group-[.is-sticky]:hidden">
                    {{ props.partDetail.Description }}
                </div>

                <div class="flex gap-4 lg:group-[.is-sticky]:hidden">
                    <PartLifecycleRisk :risk="props.partDetail?.LifecycleRisk" />
                    <PartSupplyChainRisk :risk="props.partDetail?.SupplyChainRisk" />
                    <PartTariff
                        v-if="props.partDetail?.ShowTariffWarning && props.partDetail?.IsAffectedByTariff"></PartTariff>
                </div>
            </div>
            <div class="col-start-1 sm:col-start-2 lg:col-start-3 flex flex-wrap gap-2 lg:justify-end">
                <div
                    v-if="props.partDetail.DatasheetUrl"
                    :data-source="ANALYTICS_SOURCE.PART_DETAIL"
                    :data-mfr="`${props.partDetail.ManufacturerName} [${props.partDetail.ManufacturerId}]`"
                    :data-mpn="props.partDetail.PartNumber"
                    :data-cur="props.partDetail.CurrencyCode"
                    :data-dist="distributorData"
                    :data-hash="props.partDetail.PartClickHash">
                    <Button
                        id="part-detail-datasheeturl"
                        as="a"
                        variant="link"
                        :href="props.partDetail.DatasheetUrl"
                        target="_blank"
                        rel="noopener nofollow"
                        :title="$t('PartDetail.ViewDatasheet')"
                        data-ga="s:Datasheet">
                        <EnhancedImage :src="pdfIconUrl" :alt="$t('PartDetail.ViewDatasheet')" height="24" width="24" />
                        <span>{{ $t("Global.Datasheet") }}</span>
                    </Button>
                </div>
                <div v-if="showPartHeaderButtons" class="group-[.is-sticky]:lg:hidden">
                    <NotificationButton
                        v-if="showNotification"
                        id="btnNotification"
                        :has-notification
                        @click="notificationDialog?.openDialogPartRecord(props.part, props.partNotification)" />
                </div>

                <PartAddToBom v-if="showPartHeaderButtons" :part="props.part" class="group-[.is-sticky]:lg:hidden" />
                <PartModelsLink
                    :part-number="props.partDetail.PartNumber"
                    :manufacturer="props.partDetail.ManufacturerName"
                    :href="props.partDetail.PartModelsUrl"
                    :has-models
                    class="group-[.is-sticky]:lg:hidden" />
            </div>
        </div>
        <div class="hidden px-4 py-2 border-solid border-gray-200 border-t border-b lg:flex space-x-4">
            <slot />
        </div>
    </div>
</template>
<script setup lang="ts">
import { useWindowScroll, refThrottled } from "@vueuse/core";
const localePath = useLangPath();
const config = useRuntimeConfig();
const { y } = useWindowScroll();
const scrollY = refThrottled(y, 250);

const props = defineProps<{
    small?: boolean;
    part: PartRecord;
    partDetail: PartDetail;
    partNotification: PartNotification | null;
    hasChart: boolean;
    hasSimilarParts: boolean;
    notificationDialog?: NotificationDialog | null;
}>();

const cdnUrl = config.public.cdnUrl;
const pdfIconUrl = cdnUrl + "/assets/images/adobe_pdf_file_icon_24x24.png";

const hasModels = computed(() => props.partDetail?.PartModelStatus === PART_MODEL_STATUS.ModelsExist);

const isStickyHeader = computed(() => scrollY.value >= 15);

const showNotification = computed(() => props.part.Id > 0);

const hasNotification = computed(
    () =>
        props.partNotification != null &&
        (props.partNotification.PriceAlerts?.length > 0 || props.partNotification.StockAlerts?.length > 0)
);

const distributorData = computed(() =>
    props.partDetail.DatasheetDistributorId
        ? `${props.partDetail.DatasheetDistributorName} [${props.partDetail.DatasheetDistributorId}]`
        : ""
);

const showPartHeaderButtons = computed(() => {
    return props.part?.Id && props.partDetail.IsActivePart;
});
</script>
