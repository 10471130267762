<template>
    <div class="text-center origin-top-left px-2">
        <div v-if="props.images.length" id="part-header-carousel" class="relative mt-0 p-0">
            <!-- Wrapper for slides -->
            <div class="w-full relative">
                <div
                    v-for="(image, index) in props.images"
                    :key="image.Url"
                    class="left-0"
                    :class="{ hidden: activeIndex != index }">
                    <div>
                        <a
                            v-if="image.ProductUrl"
                            :href="image.ProductUrl"
                            data-ga="s:PartDetailImage"
                            :data-dist="image.DistributorId"
                            target="_blank"
                            rel="noopener nofollow"
                            class="block"
                            :title="image.TitleName">
                            <PartImage
                                size="lg"
                                importance="high"
                                class="mx-auto"
                                :image-url="image.Url"
                                :title="image.TitleName"
                                :alt="image.AltDescription"
                                :self-hosted="!image.DistributorId" />
                        </a>
                        <PartImage
                            v-else
                            size="lg"
                            importance="high"
                            class="mx-auto"
                            :image-url="image.Url"
                            :title="image.TitleName"
                            :alt="image.AltDescription"
                            :self-hosted="!image.DistributorId" />
                    </div>
                    <div v-if="image.Source" class="mt-2 leading-tight group-[.is-sticky]:hidden max-lg:hidden">
                        <small>
                            {{ $t("PartDetail.ImageCourtesyOf") }}
                            <a
                                :href="image.ProductUrl"
                                data-ga="s:PartDetailImageDistributorName"
                                :data-dist="image.DistributorId"
                                target="_blank"
                                rel="noopener nofollow"
                                :title="image.Source">
                                {{ image.Source }}
                            </a>
                        </small>
                    </div>
                </div>

                <!-- Left and right controls -->
                <button
                    v-if="props.images.length > 1"
                    class="carousel-arrow link-gray-light left-0"
                    @click="previousImage">
                    <span class="fass fa-chevron-left" />
                    <span class="sr-only">{{ $t("PartDetail.Previous") }}</span>
                </button>
                <button
                    v-if="props.images.length > 1"
                    class="carousel-arrow link-gray-light right-0"
                    @click="nextImage">
                    <span class="fass fa-chevron-right" />
                    <span class="sr-only">{{ $t("PartDetail.Next") }}</span>
                </button>
            </div>
        </div>
        <div v-else>
            <span class="fass fa-image generic-image text-gray-200 text-[80px]" />
            <p class="hidden md:block">
                <small>{{ $t("PartDetail.NoImageAvailable") }}</small>
            </p>
        </div>
    </div>
</template>
<script setup lang="ts">
type ProductImage = {
    Url: string;
    ProductUrl?: string;
    DistributorId?: number;
    TitleName: string;
    AltDescription: string;
    Source?: string;
};

const props = defineProps({
    images: { type: Array<ProductImage>, required: true },
});

const activeIndex = ref(0);
function nextImage() {
    activeIndex.value++;

    if (activeIndex.value >= props.images.length) {
        activeIndex.value = 0;
    }
}

function previousImage() {
    activeIndex.value--;

    if (activeIndex.value < 0) {
        activeIndex.value = props.images.length - 1;
    }
}
</script>
<style scoped>
.carousel-arrow {
    @apply absolute w-[15%] h-[100px] top-0 flex items-center justify-center text-3xl;
}
</style>
