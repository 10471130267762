<template>
    <section v-if="props.results.Parts && props.results.Parts.length" id="part-detail-similar-parts">
        <h2 class="part-page-heading">{{ $t("PartDetail.SimilarProducts") }}</h2>
        <p v-html="$t('PartDetail.SimilarProductsDisclaimer')"></p>
        <div class="table-scroll">
            <table id="SimilarPartsTable" class="table table-grid table-similar-parts">
                <tbody>
                    <tr>
                        <td rowspan="2"></td>
                        <td class="bg-gray-50">{{ $t("PartDetail.ThisPart") }}</td>
                        <td :colspan="props.results.Parts.length - 1">
                            {{ $t("PartDetail.SimilarProducts") }}
                        </td>
                        <td class="bg-gray-50 !w-auto" rowspan="2"></td>
                    </tr>
                    <!-- Second row is product info -->
                    <tr>
                        <td v-for="similarPart of props.results.Parts" :key="similarPart.PartDetail.PartId">
                            <div class="flex flex-col items-center justify-center p-1">
                                <div v-if="similarPart.IsCurrentPart" class="pb-2.5">
                                    <PartImage size="lg" :part="similarPart" />
                                </div>
                                <NuxtLink
                                    v-else
                                    :href="similarPart.PartUrl"
                                    class="pb-2.5"
                                    @click="viewSimilarPart(similarPart)">
                                    <PartImage size="lg" :part="similarPart" />
                                </NuxtLink>

                                <div>{{ similarPart.Manufacturer.Name }}</div>
                                <NuxtLink
                                    v-if="!similarPart.IsCurrentPart"
                                    :href="similarPart.PartUrl"
                                    @click="viewSimilarPart(similarPart)">
                                    {{ similarPart.PartDetail.PartNumber }}
                                </NuxtLink>
                                <span v-else>{{ similarPart.PartDetail.PartNumber }}</span>
                            </div>
                        </td>
                    </tr>

                    <!-- Stock -->
                    <tr>
                        <td class="!text-right">
                            <b>{{ $t("Global.Stock") }}</b>
                        </td>
                        <td
                            v-for="similarPart of props.results.Parts"
                            :key="similarPart.PartDetail.PartId"
                            class="bg-gray-50 text-center">
                            <template v-if="similarPart.IsCurrentPart">
                                <div v-if="props.partResult.TotalStock > 0" class="text-success">
                                    <span>
                                        {{ props.partResult.TotalStock.toLocaleString() }}
                                        {{ $t("Global.InStock") }}
                                    </span>
                                    <span>
                                        <i class="fass fa-check"></i>
                                    </span>
                                </div>
                                <div v-else>
                                    <span class="text-muted">{{ $t("Global.OutOfStock") }}</span>
                                </div>
                                <a href="#part-results">
                                    {{ $t("PartCategory.ViewPriceAndAvailability") }}
                                </a>
                            </template>
                            <template v-else>
                                <div v-if="similarPart.TotalStock > 0" class="text-success">
                                    <span>
                                        {{ similarPart.TotalStock.toLocaleString() }} {{ $t("Global.InStock") }}
                                        <i class="fass fa-check"></i>
                                    </span>
                                </div>
                                <div v-else>
                                    <span class="text-muted">{{ $t("Global.OutOfStock") }}</span>
                                </div>
                                <button class="link-blue" @click="viewPartResults(similarPart)">
                                    {{ $t("PartCategory.ViewPriceAndAvailability") }}
                                </button>
                            </template>
                        </td>
                        <td class="bg-gray-50 !w-auto">
                            <a :href="props.results.PartCategoryUrl" @click="viewParametricSearch()">
                                {{ $t("PartDetail.ViewMoreProducts") }}
                            </a>
                        </td>
                    </tr>

                    <tr>
                        <td class="!text-right">
                            <b>{{ $t("Global.LifecycleRisk") }}</b>
                        </td>

                        <!-- Update "this part" lifecycle risk when live results come back -->
                        <td
                            v-for="similarPart of props.results.Parts"
                            :key="similarPart.PartDetail.PartId"
                            :class="{ 'bg-gray-50': similarPart.IsCurrentPart }">
                            {{ lifecycleRiskLabel(similarPart) }}
                        </td>

                        <td class="bg-gray-50 !w-auto" :rowspan="similarSpecs.length + 2">
                            <!-- {{ add 2 rows for risk rows }}-->
                            <a :href="props.results.PartCategoryUrl" @click="viewParametricSearch()">{{
                                $t("PartDetail.ViewMoreProductsSimilarSpecs", [props.results.Name])
                            }}</a>
                        </td>
                    </tr>

                    <tr>
                        <td class="!text-right">
                            <b>{{ $t("Global.SupplyChainRisk") }}</b>
                        </td>
                        <!-- Update "this part" supply chain risk when live results come back -->
                        <td
                            v-for="similarPart of props.results.Parts"
                            :key="similarPart.PartDetail.PartId"
                            :class="{ 'bg-gray-50': similarPart.IsCurrentPart }">
                            {{ supplyChainRiskLabel(similarPart) }}
                        </td>
                    </tr>

                    <tr v-for="spec in similarSpecs" :key="spec.PartSpecificationId">
                        <td class="!text-right">
                            <b>{{ spec.TranslatedName ?? spec.Name }}</b>
                        </td>
                        <td
                            v-for="similarPart of props.results.Parts"
                            :key="similarPart.PartDetail.PartId"
                            :class="{ 'bg-gray-50': similarPart.IsCurrentPart }">
                            <div>{{ getPartSpec(similarPart, spec) }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <PartResultsDialog
            ref="partDialog"
            :analytics-source="ANALYTICS_SOURCE.PART_DETAIL"
            @click="onPartResultsClick" />
    </section>
</template>
<script setup lang="ts">
import { PartResultsDialog } from "#components";
import type { ClickSource } from "../part/PartResultsDialog.vue";

const { t } = useI18n();
const { gtag } = useGtag();
const props = defineProps<{ partResult: PartDetail; results: SimilarPartsResponse }>();
const similarSpecs = computed(() => props.results.Specs);

const partDialog = ref<typeof PartResultsDialog | null>(null);

const getPartSpec = (similarPart: SimilarPart, spec: PartSpecification) => {
    const similarSpec = similarPart.PartDetail.Specs.find((x) => x.PartSpecificationId == spec.PartSpecificationId);
    if (similarSpec && similarSpec.Value) {
        return similarSpec.Value;
    } else {
        return "—"; //&mdash
    }
};

const viewSimilarPart = (part: SimilarPart) => {
    gtag("event", "similar_part_view", {
        manufacturer_name: part.Manufacturer.DisplayName,
        part_number: part.PartDetail.PartNumber,
    });
};

const viewParametricSearch = () => {
    gtag("event", "similar_part_more", {
        manufacturer_name: props.partResult.ManufacturerName,
        part_number: props.partResult.PartNumber,
    });
};

const viewPartResults = (part: SimilarPart) => {
    gtag("event", "similar_part_view_availability", {
        manufacturer_name: part.Manufacturer.DisplayName,
        part_number: part.PartDetail.PartNumber,
    });
    if (partDialog.value) {
        const category = props.partResult.CategoryHierarchy.slice(-1);

        partDialog.value.openSimilarPart(part, category[0]);
    }
};

function lifecycleRiskLabel(part: SimilarPart) {
    const risk = part.IsCurrentPart ? props.partResult.LifecycleRisk || part.LifecycleRisk : part.LifecycleRisk;
    const option = LIFECYCLE_RISK_OPTIONS[risk];
    return option.label ? t(option.label) : "";
}

function supplyChainRiskLabel(part: SimilarPart) {
    const risk = part.IsCurrentPart ? props.partResult.SupplyChainRisk || part.SupplyChainRisk : part.SupplyChainRisk;
    const option = SUPPLYCHAIN_RISK_OPTIONS[risk];
    return option.label ? t(option.label) : "";
}

function onPartResultsClick(source: ClickSource, part: CommonPart | null) {
    if (!part) return;

    if (source == "datasheet") {
        gtag("event", "similar_product_modal_datasheet", { part_number: part.PartNumber });
    } else if (source === "distributor_datasheet") {
        gtag("event", "similar_product_distributor_datasheet", { part_number: part.PartNumber });
    } else if (source === "distributor_part") {
        gtag("event", "similar_product_distributor_part", { part_number: part.PartNumber });
    }
}
</script>
