<template>
    <div class="content-container">
        <template v-if="part && partDetail && partDetail.PartExists">
            <PartHeader
                :part="part"
                :part-detail="partDetail"
                :part-notification="partNotification"
                :has-chart="!!partChartData"
                :has-similar-parts="hasSimilarParts"
                :notification-dialog="notificationDialog">
                <RouterLink
                    v-for="link in navLinks"
                    :key="link.id"
                    :to="{ hash: `#${link.id}` }"
                    :class="link.id === activeId ? 'font-bold border-solid border-b-2 border-accent-secondary' : ''"
                    @click="clickNavLink(link.id)">
                    {{ link.label }}
                </RouterLink>
            </PartHeader>

            <div class="px-4">
                <PartResults id="part-results" class="scroll-mt-80 xl:scroll-mt-64" :part-result="partDetail" />
                <PartHistory
                    id="part-history"
                    class="scroll-mt-80 xl:scroll-mt-64"
                    :part="commonPart"
                    :part-chart-data="partChartData" />
                <PartSpecs
                    v-if="hasSpecs"
                    id="part-specs"
                    class="scroll-mt-80 xl:scroll-mt-64"
                    :part-result="partDetail" />
                <PartSimilar
                    v-if="similarParts"
                    id="part-similar"
                    class="scroll-mt-80 xl:scroll-mt-64"
                    :part-result="partDetail"
                    :results="similarParts" />
                <PartDescriptions
                    id="part-descriptions"
                    class="scroll-mt-80 xl:scroll-mt-64"
                    :descriptions="partDetail.AllDescriptions || []" />
                <PartManufacturerNames
                    class="scroll-mt-80 xl:scroll-mt-64"
                    :names="partDetail.MfrAlternateNames || []"
                    :manufacturer-name="partDetail.ManufacturerName || ''" />
            </div>

            <NotificationDialog ref="notificationDialog" @update="updateNotifications" />
        </template>
        <div v-else-if="partError" class="container-fluid">
            <h1 class="text-danger text-4xl mb-6">{{ $t("PartDetail.PartNotFound") }}</h1>
            <a :href="partError.data.NotFoundSearchUrl" class="text-xl font-light">
                {{ $t("PartDetail.SwitchToSearch", [partNumber]) }}
            </a>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useActiveScroll } from "vue-use-active-scroll";
import NotificationDialog from "~/components/notification/NotificationDialog.vue";

const client = import.meta.client;

const event = useRequestEvent();
const { toast } = useToast();
const { t } = useI18n();
const route = useRoute();
const globalConfig = useStateGlobalConfig();
const regions = useStateRegionInfo();
const locationInfo = useStateLocationInfo();
const { gtag } = useGtag();

const { company, partNumber } = route.params;
const similarParts = ref<SimilarPartsResponse>();
const partChartData = ref<PartChartData | null>(null);
const notificationDialog = useTemplateRef("notificationDialog");

const hasSimilarParts = computed(() => !!similarParts.value && similarParts.value.Parts?.length > 0);
globalConfig.value.SearchText = Array.isArray(partNumber) ? partNumber[0] : partNumber;

const fixParameter = (param: string | string[]) => encodeURIComponent(Array.isArray(param) ? param[0] : param);

const { data: partDetail, error: partError } = await useFetchLocaleApi<PartDetail>(
    `api/part-detail/${fixParameter(company)}/${fixParameter(partNumber)}`,
    { query: Object.assign({}, route.query), deep: false }
);

const part = computed(() => partDetail.value?.Part);
const commonPart = computed(() =>
    part.value ? ({ ...part.value, PartId: part.value.Id, IsSelfHostedImage: false } as CommonPart) : undefined
);
const partNotification = ref<PartNotification | null>(partDetail.value?.PartNotification ?? null);

const hasHistory = computed(() => !!partDetail.value?.Part && partChartData.value);
const hasSpecs = computed(() => partDetail.value?.Specs && partDetail.value?.Specs.length > 0);
const navLinks = computed(() => {
    return [
        { id: "part-results", label: t("PartDetail.ResultsHeading") },
        hasHistory.value ? { id: "part-history", label: t("PartDetail.StockAndPriceHistory") } : null,
        hasSpecs.value ? { id: "part-specs", label: t("PartDetail.Specifications") } : null,
        hasSimilarParts.value ? { id: "part-similar", label: t("PartDetail.SimilarProducts") } : null,
        { id: "part-descriptions", label: t("PartDetail.Descriptions") },
    ].filter((link) => link !== null);
});

const navLinksIds = computed(() => navLinks.value.map((link) => link.id));

const { setActive, activeId } = useActiveScroll(navLinksIds, { overlayHeight: 300 });

if (event && !partDetail.value?.PartExists) {
    setResponseStatus(event, 404);
} else if (partDetail.value) {
    useSetCanonicalUrl(partDetail.value.CanonicalPartUrl);

    if (!partDetail.value.IsComplete && partDetail.value.PartResultsUrl) {
        // we need to get the completed results for an existing search
        useFetchLocaleApi(partDetail.value.PartResultsUrl, {
            lazy: true,
            server: false,
            onResponse({ response }) {
                partDetail.value = response._data;
            },
        });
    }
    if (partDetail.value.ProductDataJson) {
        useHead({
            script: [{ type: "application/ld+json", children: partDetail.value.ProductDataJson }],
        });
    }
    if (part.value) {
        const title = format("{0} - {1} - TrustedParts.com", part.value.PartNumber, part.value.Manufacturer.Name);
        const description = t("PartDetail.MetaDescriptionFormat", [part.value.Manufacturer, part.value.PartNumber]);
        useSeoMeta({ title, ogTitle: title, description, ogDescription: description });
    }

    // Show Home Region Warning if appropriate
    if (client && partDetail.value.ShowHomeRegionWarning) {
        const homeRegion = regions.value.find((x) => x.RegionId == locationInfo.value.HomeRegionId);
        const message = `${t("Search.HomeRegionName", [homeRegion?.Name])} ${
            partDetail.value.CanViewAmericasPricing ? t("Search.HomeRegionChina") : t("Search.HomeRegionOther")
        }`;

        toast({ description: message, variant: "warning" });
    }

    if (partDetail.value.IsUndesirableSearchTerm) {
        useHead({
            meta: [{ name: "robots", content: "noindex" }],
        });
    }

    useFetchApi("api/part-chart", {
        lazy: true,
        server: false,
        method: "POST",
        body: {
            Parts: [{ PartNumber: part.value?.PartNumber, ManufacturerId: part.value?.Manufacturer.Id }],
        },
        onResponse({ response }) {
            partChartData.value = response._data[0];
        },
    });

    useFetchApi<SimilarPartsResponse>(`api/part/${part.value?.CanonicalPartId}/similar-parts`, {
        lazy: true,
        server: false,
        method: "POST",
        onResponse({ response }) {
            similarParts.value = response._data;
        },
    });
}

const updateNotifications = (record: PartNotification) => {
    partNotification.value = record;
};

function clickNavLink(id: string) {
    gtag("event", "part_page_navigation", { section_name: id });
    setActive(id);
}
</script>
