<!-- eslint-disable vue/no-v-html -->
<template>
    <section>
        <h2 class="part-page-heading">{{ $t("PartDetail.ResultsHeading") }}</h2>
        <div class="table-scroll">
            <table v-if="hasResults" id="ExactMatchesTable" class="table table-search-results">
                <thead>
                    <tr>
                        <th v-html="$t('Search.AuthorizedDistributor')"></th>
                        <th v-hidden="'xs,sm,md,lg-1270'">{{ $t("Search.RoHS") }}</th>
                        <th v-hidden="'xs'">{{ $t("Global.Datasheet") }}</th>
                        <th v-hidden="'xs,sm,md'">{{ $t("Search.Supplier_Number") }}</th>
                        <th v-hidden="'xs,sm,md'">{{ $t("Search.PackagingHeader") }}</th>
                        <PartStockHeader :notification="props.partResult?.PartNotification" />
                        <th>{{ $t("Search.Pricing") }}</th>
                        <th class="w-px print:hidden"></th>
                    </tr>
                </thead>
                <tbody>
                    <PartResultsRow
                        v-for="result of results"
                        :key="result.SortOrder"
                        v-model:expanded="expanded[result.SortOrder]"
                        :show-child="expanded[result.ParentSortOrder]"
                        :item="result"
                        :duplicate-count="partDuplicateCounts[result.SortOrder]"
                        :show-rohs="true"
                        :click-hash="props.partResult?.PartClickHash"
                        :click-data-source="ANALYTICS_SOURCE.PART_DETAIL" />
                </tbody>
            </table>
            <div v-if="!hasResults" v-html="$t('Search.MessageNoMatchesHeader')" />
        </div>
        <p class="font-bold">
            <a :href="searchLink"> {{ seeMoreLinkText }} </a>
        </p>
    </section>
</template>
<script setup lang="ts">
const props = defineProps<{ partResult?: PartDetail }>();
const searchUrl = useSearchUrl();
const { t } = useI18n();

const expanded = ref<Record<string, boolean>>({});

const hasResults = computed(() => props.partResult && props.partResult.Results && props.partResult.Results.length);
const seeMoreLinkText = computed(() => t("PartDetail.SeeAllAuthorizedResults", [props.partResult?.DisplayPartNumber]));
const searchLink = computed(() => searchUrl(props.partResult?.DisplayPartNumber ?? ""));

const results = computed(() => {
    if (!props.partResult || !props.partResult.Results) {
        return [];
    }

    return [...props.partResult.Results].sort((a, b) => {
        return new String(a.SortOrder).localeCompare(b.SortOrder);
    });
});

const partDuplicateCounts = computed(() => getPartDuplicateCounts(results.value));
</script>
